<template>
  <b-card-code v-if="$checkPermission($options.name, 'view')" id="user-history" no-body>

    <div class="page-header">
      <b-row>
        <b-col class="filtersContainer" md="12">

          <div class="filters">

            <div v-if="$checkPermission($options.name, 'insert')" class="filter-column">
              <b-button @click="add" variant="primary">Ավելացնել</b-button>
            </div>

          </div>

        </b-col>
      </b-row>
    </div>

    <b-row>
      <b-col md="12">
        <b-table
            striped
            hover
            responsive
            class="position-relative"
            :items="users"
            :fields="fields"
        >
          <template #cell(createdAt)="data">
            {{ data.value | formatDate }}
          </template>

          <template #cell(title)="data">
            {{ data.item.title }} {{data.item.requestsCount}}
          </template>

          <template #cell(actions)="data">
            <fa-icon v-if="$checkPermission($options.name, 'update')" @click="changeStatus(data.item)" icon="power-off"
                     :class="'table-action-icon item-status status-' + data.item.status"/>
            <fa-icon v-if="$checkPermission($options.name, 'update')" @click="edit(data.item)" icon="edit" class="table-action-icon accent"/>
            <fa-icon v-if="$checkPermission($options.name, 'delete')" @click="deleteItem(data.item)" icon="trash-alt" class="table-action-icon danger"/>
          </template>

        </b-table>
      </b-col>
    </b-row>

    <b-modal
        id="modal-form"
        :no-close-on-backdrop="true"
        cancel-variant="outline-secondary"
        :ok-title="modal_title"
        cancel-title="Կասեցնել"
        centered
        size="sm"
        :title="modal_title"
        :is-valid="false"
        @ok="confirmForm"
        :ok-disabled="validateForm"
    >
      <b-form>

        <b-form-checkbox
            v-model="form.status"
            name="check-button"
            class="custom-control-success mb-2"
            switch
            inline
        >
          Կարգավիճակ
        </b-form-checkbox>

        <b-form-group>
          <b-form-input
              v-model="form.name"
              id="name"
              type="text"
              placeholder="Անուն"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.title"
              id="title"
              type="text"
              placeholder="Կազմակերպության անվանում"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.token"
              id="token"
              type="text"
              placeholder="Անվտանգության կոդ"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.ip"
              id="ip"
              type="text"
              placeholder="IP"
          />
        </b-form-group>
        <b-form-checkbox
            v-model="form.ip_required"
            name="check-button"
            class="custom-control-success mb-2"
            switch
            inline
        >
          IP-ն պարտադիր է
        </b-form-checkbox>
        <b-form-group>
          <b-form-input
              v-model="form.url"
              id="url"
              type="text"
              placeholder="API URL"
          />
        </b-form-group>
        <b-form-group>
          <b-form-input
              v-model="form.method"
              id="method"
              type="text"
              placeholder="method"
          />
        </b-form-group>
      </b-form>
    </b-modal>

  </b-card-code>
</template>

<script>

import moduleApi from '@/store/api/moduleApi'
import store from '@/store'
import {
  BTable,
  BCardBody,
  BFormGroup,
  BPagination,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormCheckbox
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode'

export default {
  name: 'api-users',
  components: {
    BCardCode,
    BCardBody,
    BTable,
    BFormGroup,
    BPagination,
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormCheckbox
  },
  data() {
    return {
      API_STORE_MODULE_NAME: 'apiModule',
      fields: [
        {
          key: 'title',
          label: 'Ամսաթիվ'
        },
        {
          key: 'url',
          label: 'API URL'
        },
        {
          key: 'actions',
          label: 'Կարգավիճակ',
          class: 'text-center width-10-per px-0',
        },
      ],
      addForm: false,
      editForm: false,
      formFields: {
        name: '',
        status: true,
        title: '',
        token: '',
        ip: '',
        ip_required: false,
        url: '',
        method: 'POST'
      },
      form: {}
    }
  },
  computed: {
    users() {
      return this.$store.state[this.API_STORE_MODULE_NAME].users
    },
    modal_title() {
      return (this.addForm) ? 'Ավելացնել' : 'Խմբագրել'
    },
    validateForm() {
      return this.form.name === ''
          || this.form.title === ''
          || this.form.token === ''
          || this.form.url === ''
          || this.form.method === ''
          || (this.form.ip_required && this.form.ip === '')
    }
  },
  created() {
    if (!store.hasModule(this.API_STORE_MODULE_NAME)) store.registerModule(this.API_STORE_MODULE_NAME, moduleApi)
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$vs.loading()
      this.$store.dispatch(`${this.API_STORE_MODULE_NAME}/getUsers`)
          .then(() => {
            this.$vs.loading.close()
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    add() {
      this.editForm = false
      this.addForm = true
      this.form = { ...this.formFields }
      this.$bvModal.show('modal-form')
    },
    edit(item) {
      this.addForm = false
      this.editForm = true
      this.form = this.$store.getters['getForm'](false)(this.formFields)(item)
      this.$bvModal.show('modal-form')
    },

    confirmForm() {
      const action = (this.addForm) ? 'addApi' : 'editApi'
      this.$store.dispatch(`${this.API_STORE_MODULE_NAME}/${action}`, this.form)
          .then(() => {
            this.$vs.loading.close()
          })
          .catch(error => {
            this.$vs.loading.close()
            this.$root.$emit('getError', error)
          })
    },

    changeStatus (item) {
      const status = !item.status
      const action = (status) ? 'ակտիվացնել' : 'պասիվացնել'
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք ' + action + ' API-ն',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              const payload = JSON.parse(JSON.stringify(item))
              payload.status = status
              this.$store.dispatch(this.API_STORE_MODULE_NAME + '/editApi', payload)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: '',
                      text: 'Փոփոխությունները պահպանվեցին',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    },

    deleteItem (item) {
      this.$swal({
        title: 'Հաստատե՞լ գործողությունը',
        text: 'Դուք ցանկանում եք հեռացնել API-ն',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Հաստատել',
        cancelButtonText: 'Մերժել',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.$store.dispatch(this.API_STORE_MODULE_NAME + '/deleteApi', item._id)
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: 'Հեռացվել է!',
                      text: 'API-ն հաջողությամբ հեռացվեց',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                  })
                  .catch((error) => {
                    this.$root.$emit('getError', error)
                  })
            }
          })
    }
  }
}
</script>
